let ShowcasePage = () => import(/* webpackChunkName: "Nebula" */'../components/ShowcasePage.vue')
let CopyrightPage = () => import(/* webpackChunkName: "Nebula" */'../components/CopyrightPage.vue')
let CopyrightDetailPage = () => import(/* webpackChunkName: "Nebula" */'../components/CopyrightDetailPage.vue')
let AboutPage = () => import(/* webpackChunkName: "Nebula" */'../components/AboutPage')
let HomePage = () => import(/* webpackChunkName: "Nebula" */'../components/HomePage')
let PostPage = () => import(/* webpackChunkName: "Nebula" */'../components/PostPage')
let TranslatorPage = () => import(/* webpackChunkName: "Nebula" */'../components/TranslatorPage')
let Layout = () => import(/* webpackChunkName: "Nebula" */'../components/layout/MainPageLayout')
let SignInPage = () => import(/* webpackChunkName: "Nebula" */'../components/SignInPage')

let routes = [{
    name: 'HomePage',
    path: '/',
    component: Layout,
    children: [{
        name: 'Home',
        path: '',
        component: HomePage
    }, {
        name: 'Showcase',
        path: 'showcase',
        component: ShowcasePage,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query || "",
                tag: route.query.tag || "",
                workLength: route.query.workLength || ",",
            }
        },
    }, {
        name: 'Translator',
        path: 'translator',
        component: TranslatorPage,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query || "",
                language: route.query.language || ""
            }
        },
    }, {
        name: 'Copyright',
        path: 'copyright',
        component: CopyrightPage,
        props: (route) => {
            return {
                page: route.query.page ? parseInt(route.query.page): 1,
                query: route.query.query || "",
                initial: route.query.initial || "",
                role: route.query.role || "",
                sorter: route.query.sorter || ""
            }
        },
    }, {
        name: 'CopyrightDetail',
        path: 'copyright/:copyrightId',
        component: CopyrightDetailPage,
    }, {
        name: 'Post',
        path: 'posts',
        component: PostPage
    }, {
        name: 'About',
        path: 'about',
        component: AboutPage
    }]
}, {
    name: "SignIn",
    path: "/sign_in",
    component: SignInPage
}, {
    path: '*',
    redirect: '/'
}]

export default routes