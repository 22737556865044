import request from "./request";

export function checkSession () {
    return request({
        url: `session`,
        method: 'GET'
    })
}

export function switchSession (isAdmin) {
    return request({
        url: `${isAdmin ? '' : 'users/'}session`,
        method: 'PUT'
    })
}

export function signIn (isAdmin, data) {
    return request({
        url: `${isAdmin ? '' : 'users/'}session`,
        method: 'POST',
        data
    })
}

export function signOut (isAdmin) {
    return request({
        url: `${isAdmin ? '' : 'users/'}session`,
        method: 'DELETE'
    })
}